































































































import Vue from 'vue';
import ToastMessage from '@/models/ToastMessage';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import { MetaInfo } from 'vue-meta';

const validations = {
  form: {
    username: { required, email },
    password: { required },
    rememberMe: {},
  },
};

export default Vue.extend({
  mixins: [validationMixin],
  validations: validations,
  components: { ValidatedInput, ValidatedCheckbox },
  data() {
    return {
      form: {
        username: '',
        password: '',
        rememberMe: true,
      },
      loading: false,
      message: '',
    };
  },
  mounted() {
    this.setBreadCrumb();
  },
  metaInfo(): MetaInfo {
    return { title: this.$t('pages.auth.Login.meta.title').toString() };
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [{ translationKey: 'pages.auth.Login.breadcrumb.last' }]);
    },
    handleLogin() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.message = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        this.loading = true;
        this.message = '';
        this.$store.dispatch('AuthStoreModule/login', this.form).then(
          () => {
            this.$nextTick(() => {
              this.$router.push({ name: 'PublicPlayroomIndex' });
              this.$store.commit('addToastMessage', new ToastMessage('Login erfolgreich', 'bg-success'));
            });
          },
          (error) => {
            this.loading = false;
            this.message = error;
          }
        );
      }
    },
  },
});
